<template>
  <Header/>
  <Content/>
  <Footer/>
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Content from '@/components/Content.vue'

export default defineComponent({
  components: {
    Header,
    Footer,
    Content
  }
})
</script>
